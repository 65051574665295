const DEFAULT_LOCALHOST = "eu-prod";
export const win =
  typeof window != "undefined" ? window : ({ location: {} } as any);
const { hostname } = win.location;
const stages = [
  {
    stage: "eu-dev",
    hosts: ["localhost", "app-dev.newlife.io", "172.20.10.2"],
  },
  {
    stage: "eu-prod",
    hosts: ["os.newcoin.org"],
  },
];

const currHostEntry = stages.find((e) => e.hosts.find((v) => v === hostname));

export const stage =
  hostname == "localhost" ? DEFAULT_LOCALHOST : currHostEntry?.stage; // domain-based detection later
