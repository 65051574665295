export const win = typeof window != "undefined" ? window : ({ location: {} } as any);

import { Signal, signal } from "@preact/signals-react";
import {
  ContentType,
  UserReadPrivateResponse,
  UserReadPublicResponse,
} from "@newstackdev/iosdk-newgraph-client-js";
import { cache, newgraphClient, newgraphClientManager } from "..";
import { progressiveHandler } from "../ProgressiveHandler";
import { cacheUsers } from "./user";
import { newgraphBaseUrl } from "../config";
import { firebaseToken, recaptcaVerifier } from "./firebase";
import { ProgressEntry } from "../ProgressiveHandler/progressEntry";

const isSsr = typeof window == "undefined";

const JWT_LOCALSTORAGE = "newsafe-auth-token";

const localStorage =
  typeof window !== "undefined" && (window as any).localStorage;

const urlJwt: string =
  new URLSearchParams((win.location.search || "").replace(/^\?/, "")).get(
    "newsafe_jwt"
  ) || "";

const urlJwt2: string =
  new URLSearchParams((win.location.search || "").replace(/^\?/, "")).get(
    "token"
  ) || "";

if (urlJwt && !isSsr) localStorage.setItem(JWT_LOCALSTORAGE, urlJwt);

if (urlJwt2 && !isSsr) localStorage.setItem(JWT_LOCALSTORAGE, urlJwt2);

const storedJwt: string =
  (localStorage && !isSsr && localStorage.getItem(JWT_LOCALSTORAGE)) || "";

const maybeToken = (token: string) => (token ? `newsafe ${token}` : "");

export const token: Signal<SessionTokenResponse> = signal({
  jwt: maybeToken(storedJwt),
});

// if (!isSsr && storedJwt) {
//     const t = "newsafe " + storedJwt;

//     token.value = { jwt: t };

//     // newgraphClient.setSecurityData({ token: t });
//     // current();

//     //
// }
export interface SessionTokenResponse {
  jwt?: string;
}

export const _current: Signal<UserReadPrivateResponse> = signal({});
export const _currentShared = {
  executions: 0
}
// export const setCurrent = (f: (u: UserReadPrivateResponse) => UserReadPrivateResponse) => (_current.value = (f(_current.value)));

const currentF = async (progress: ProgressEntry) => {
  // return progress;
  try {
    if (!newgraphClient.getCurrentToken()) {
      _current.value = {};
      return Promise.resolve(progress);
    }
    
    _currentShared.executions++;

    console.log(newgraphClient.getCurrentToken());
    const res = await newgraphClient.api.user.currentList();
    // await new Promise((res) => setTimeout(res, 5000));
    await cacheUsers(res.data as UserReadPublicResponse);
    _current.value = res.data;
  } catch (ex) {
    console.log(ex);
  } finally {
    return {
      ...progress,
      done: true,
    };
  }
};

export const current = (params?: { autostart: boolean }) =>
  progressiveHandler(
    { id: "auth-current" },
    _current.value, //Promise.resolve(current) as PromiseExtended,
    currentF,
    {
      autostart: false,
    }
  );

export const currentPassive = (params?: { autostart: boolean }) =>
  progressiveHandler(
    undefined,
    _current.value, //Promise.resolve(current) as PromiseExtended,
    currentF,
    {
      autostart: false,
    }
  );

export const signIn = async (newgraphToken: string) => {
  newgraphClient.updateToken(newgraphToken);
  const jwtRes = (
    await newgraphClient.api.request({
      baseUrl: "https://api.newsafe.org/v1",
      path: "/generateSessionToken",
      method: "POST",
      body: {
        referer: "newgra.ph",
        appOwner: "newcoinos",
        redirectUrl: "https://os.newcoin.org/explore",
        scopes: [],
      },
      secure: true,
      type: ContentType.Json,
      format: "json",
    })
  ).data;

  window?.localStorage.setItem(JWT_LOCALSTORAGE, jwtRes.jwt);

  const res = await newgraphClient.api.user.currentList();
  await cacheUsers(res.data as UserReadPublicResponse);
  _current.value = res.data;

  return jwtRes.jwt;
};


export const create = async (firebaseToken: string, user: Partial<UserReadPrivateResponse>) => {
  // newgraphClient.api.setSecurityData(newgraphToken)
  newgraphClientManager.updateToken(firebaseToken);

  try {
    const newUser: UserReadPrivateResponse = (
      await newgraphClient.api.user.userCreate(user)
    ).data;
    // _current.value = res.data;

    return newUser;
  } catch (ex) {
    debugger;
    console.log(ex);
    throw (ex);
  }
};


export const signInWithX = async () => {
  window.location.href = `${newgraphBaseUrl}/auth/provider/twitter2?token=${firebaseToken.value}&redirect_url=${window.location.origin}/feed`;
};

export const clearLocalState = async (reboot: boolean = false, keepAuth: boolean = true) => {
  const token = localStorage.getItem("newsafe-auth-token")
  localStorage.clear();

  if (keepAuth)
    localStorage.setItem("newsafe-auth-token", token);
  try {
    await cache.delete();
  } catch (ex) {
    console.error("Maybe not fully deleted cache", ex)
  }
  if (reboot) {
    await new Promise(res => setTimeout(res, 3000));
    win.location.reload();
    await new Promise(res => setTimeout(res, 3000));
  }
}

export const signOut = async () => {
  newgraphClient.updateToken("");
  _current.value = {};

  return new Promise(res => {
    setTimeout(async () => {
      await clearLocalState(false, false);
  
      // localStorage.removeItem(JWT_LOCALSTORAGE);
      token.value.jwt = "";
      _current.value = {};
      res(null);
    }, 300)  
  })
};

export const getOnesignalAuthHash = async () => {
  const res = await newgraphClient.api.auth.onesignalList();
  const authHash = await res.json();
  return authHash?.externalIdAuthHash;
};


