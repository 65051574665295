"use client";

import dynamic from "next/dynamic";
import { IOView } from "../types";
import "antd/dist/reset.css";
import "../styles/fonts/fonts.css";
import "./index.css";
import "../Components/Model/folder/Views/styles.css";
import "../styles/app.css";
import { win } from "../config/stage";

const SafeHydrate: IOView = ({ children }) => {
  return <div>{typeof window === "undefined" ? null : children}</div>;
};

if(win && win.top && (win.top != win)) {
  win.top.location.href = win.location.href;
}

// @ts-ignore
const Dynamic = dynamic(() => import("../Components/Master"), { ssr: false });
const Server = () => <>nothing</>;

export default Dynamic;
