
import { newgraphWebsocketsClient } from "../clients/newgraph-websocket";
import { processIncoming } from "./processIncoming";
import EventEmitter from "events";
import { getActivityStream } from "../actions/activityStream";

// import EventEmitter from "events";
// const EventEmitter = (typeof window != "undefined") ? (window as any).EventEmitter : function dummy() {};

export const websocketEvents = new EventEmitter();

if (typeof window != "undefined") {
  websocketEvents.on("message", (msg: any) => console.log("websocket", msg))
};

export const setNewgraphWebsocketToken = (token: string) => {
  // if (!state.api.auth.authorized) return;
  // const token = state.newsafe?.token || state.firebase?.token;

  // console.log(token);
  if (!token) return;

  newgraphWebsocketsClient.toggle(token);

  if (!token || !newgraphWebsocketsClient.socket) return;

  newgraphWebsocketsClient.socket?.addEventListener("message", (ev) => {
    if (ev.data == "pong") return;

    processIncoming(ev.data);
  });


  // .then(async (res) => {
  // })


  // websocketEvents.on("message", (ev: any) => {
  //   if (ev.data === "pong") return;

  //   processIncoming(ev.data);

  //   // const res = () || {
  //   //   data: { Items: [] },
  //   // };

  //   // const items = [...(((res.data || {}) as any).Items || [])].reverse();

  //   // state.websockets.messages.incoming.push(ev.data);
  //   // effects.ux.notification.open({ message: ev.data });
  // });

  // await Promise.allSettled(
  //   items.map((ev: any) => processIncoming({ msg: JSON.stringify({ ...ev, backlog: true }) })),
  // );
};

