export const batchAsync = <T, S extends Function, U>(
    f: S, //(...params: S[]) => Promise<void>, 
    opts: { batchSize: number, maxSecondsRetention: number },
) => {
    
        const paramsCache = [] as S[];
    const state = {
        interval: null as NodeJS.Timer | null,
        promise: null as null | Promise<any>
    };

    return async (...params: S[]) => {
        if(state.promise) {
            await state.promise;
        }

        paramsCache.push(...params);

        const flush = async () => {
            state.promise = f(...paramsCache);
            await state.promise;
            state.promise = null;
        }

        if (paramsCache.length > opts.batchSize) {
            if (!state.interval)
                state.interval = setInterval(() => {
                    flush();
                }, opts.maxSecondsRetention * 1000)
        }
        flush();

    }
}